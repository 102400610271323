@-webkit-keyframes fade {
  0%, 100% {
    opacity: 0;
  }
  50%, 10%, 20%, 80%, 90%, 70%, 60%, 40%, 30% {
    opacity: 1;
  }
}

@keyframes fade {
  0%, 100% {
    opacity: 0;
  }
  50%, 10%, 20%, 80%, 90%, 70%, 60%, 40%, 30% {
    opacity: 1;
  }
}

@keyframes fadeinSlow {
  0%, 80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeinSlow {
  0%, 80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#vrtx-frontpage h1, h1.hidden {
    display: block;
    position: absolute;
    left: -100000px;
}

.vrtx-feed a.item-title {
  color: @color-primary;
}

.uniforum-other-universities-news,
.uio-media,
.uio-events {
  a.item-title {
    color: rgb(206, 6, 6);
  }
}

h2 {
  font-size: 32px;
  font-size: 3.2rem;
}
h3 {
  font-size: 24px;
  font-size: 2.4rem;
}
h4 {
  font-size: 20px;
  font-size: 2rem;
}

/* News listing on frontpage */

/******** - START SECTION 1 & 2 - ***********/
/* Frontesak i toppen av siden. Section1 = manuelt innskrevet Tittel, bilde og lenke (ikke fra feed.)
Seksjon 2 viser prioritert artikkel, */
/* if class : hidden */
.vrtx-frontpage-full-width #main .grid-container {
  .big-image.full-width-picture.hidden,
  .big-image.large-image.hidden {
    display: none;
    margin-top: -30px;
  }
}

/* Full browser width image - fontesak */
.vrtx-frontpage-full-width #main #vrtx-main-content .grid-container .row-full-width {
  width: 100%;
  margin-top: -40px;
  background-position: top center !important;
  background-size: cover !important;

  .full-width-picture {
    .vrtx-box-content {
      max-height: 700px;
      overflow: hidden;

      a.vrtx-image {
        max-height: 700px;
      }

      .wrapper {
        width: 970px;
        overflow: hidden;
        position: absolute;
        left: 50%;
        margin-left: -485px;
        bottom: 75px;

        .form-element-wrapper {
          background: white;
          display: inline-block;
          padding: 15px;
          max-width: 62%;

          .item-title {
            display: block;
            font-weight: 700;
            font-size: 38px;
            font-size: 3.8rem;
            line-height: 42px;
            line-height: 4.2rem;
          }

          .published-date {
            display: block;
            width: 100%;
          }
        }
      }

      .vrtx-image {
        overflow: hidden;
      }
    }

    &.title-left {
      .form-element-wrapper {
        left: 0;
      }
    }

    &.title-right {
      .form-element-wrapper {
        right: 0;
      }
    }

    &.title-white {
      .wrapper {
        bottom: 40px;

        .form-element-wrapper {
          width: 100%;
          max-width: none;
          background: #00000036;
          padding: 5px 15px 0px 15px;

          a.item-title {
            color: white;
            text-shadow: 0 2px 25px rgba(0, 12, 38, 0.6);
            font-size: 50px;
            font-size: 5rem;
            line-height: 60px;
            line-height: 6rem;
          }
        }
      }
    }
  }
}

// Fix for bug when first loaded on Mac (equalizeHeight() in uio1 calculates wrong height)
.grid-container {
  .third-box-left,
  .third-box-right {
    height: auto !important;
  }
}

/* browser width image listing on frontpage when article is pasted in (not from feed!)... */
.grid-container.full-width-picture-bg {
  height: 700px;
  position: relative;
}
.big-image.full-width-picture {
  .vrtx-box-content.content-over-image {
    max-width: 62%;
    width: auto;
    float: left;
    background: white;
    margin-top: 40%;
    padding: 15px;
    h3 {
      font-weight: 700;
      font-size: 38px;
      font-size: 3.8rem;
      line-height: 42px;
      line-height: 4.2rem;
    }
  }
  &.title-right {
    float: right;
  }
}

#vrtx-main-content-2.big-image.full-width-picture {
  &.title-right {
    float: none;
    .form-element-wrapper {
      float: right;
    }
  }
}

/* Large image fontesak full width inside margin from feed */
#main .grid-container #vrtx-main-content-1 .vrtx-feed h3 a,
#main .grid-container #vrtx-main-content-2 .vrtx-feed .item-title {
  font-size: 44px;
  font-size: 4.4rem;
  line-height: 48px;
  line-height: 4.8rem;
  font-weight: bold;
}

#main .grid-container .vrtx-frontpage-box.big-image {
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
  h3 a {
    font-size: 46px;
    font-size: 4.6rem;
    line-height: 50px;
    line-height: 5rem;
    font-weight: bold;
    color: @color-primary;
  }
  .vrtx-frontpage-box-picture {
    overflow: hidden;
    max-height: 550px;
  }
  ul li a.vrtx-image {
    overflow: hidden;
    max-height: 550px;
    img {
      width: 100%;
    }
  }
}

/******** - END SECTION 1 & 2 - ***********/

/******** - START SECTION 3 - 1 large news + 2 vertical news  ***********/

/* Nyhet vertikale */
.feed-image-over.vertical-feed {
  .item-title {
    font-size: 22px;
    font-size: 2.2rem;
    font-family: arial;
    font-weight: bold;
  }
  a.vrtx-image {
    max-height: 175px;
    overflow: hidden;
    -webkit-animation: fadein 0.5s;
    animation: fadein 0.5s;
  }
}

/******** - END SECTION 3 - ***********/
.feed-pages .vrtx-feed.with-images:first-of-type {
  height: auto !important;
  overflow: hidden;
}
/* Debatt tredjedel bredde */
/* Debatt tredjedel bredde */
li.debatt1, li.debatt2, li.debatt3 {
  display: inline;
}
.grid-container .vrtx-frontpage-box-picture.debatt {
  width: 303px;
  min-height: 380px;
  max-height: 435px;
  padding-right: 0px !important;
  padding-left: 0px !important;
  max-height: 435px;
  height: auto !important; //ny
  overflow: hidden;
  background: @color-primary;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
  .vrtx-frontpage-box-picture {
    margin-top: 0px !important;
    position: relative !important;
    max-height: 145px;
    display: block;
    float: none;
    border: 2px solid @color-primary;
    left: 0px !important;
    z-index: -1;
    a {
      height: 145px;
      img {
        width: 100% !important;
      }
    }
  }
  h2 {
    position: absolute;
    margin-top: -5px;
    top: 109px; /* if img height more than 125 px */
    margin-left: 2px !important;
    a {
      color: white;
      text-transform: uppercase;
      font-size: 1.5rem;
      padding: 10px 20px;
      font-weight: normal;
      background: @color-secondary;
    }
  }
  .vrtx-box-content {
    margin-left: 0px !important;
    background: @color-primary;
    padding: 20px;
    color: white;
    p {
      font-size: 14px;
      &:first-of-type {
        font-family: Georgia;
        font-size: 19px;
      }
    }
    a {
      color: white;
      text-decoration: none;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

hr {
  border-bottom: none;
}

/* nyheter */
body #main .vrtx-frontpage-box.feed-dynamic .vrtx-box-content .vrtx-feed ul.items {
  li {
    margin-top: 0px;
    overflow: hidden;
    position: relative !important;
    height: auto;
    max-height: 465px;
    min-height: 380px;

    a.vrtx-image {
      width: 100%;
      height: 190px;
      overflow: hidden;
      -webkit-animation: fadein 1s;
      animation: fadein 1s;

      img {
        width: 100%;
        //width: auto; This line cropped images in frontpage-feed. 
        height: auto;
        min-width: 100%;
        max-width: 200%;
      }
    }

    li.item-notert {
      min-height: fit-content;
      width: fit-content;
    }

    a.item-title {
      max-height: 150px;
      overflow: hidden;
      padding-bottom: 15px;
    }

    &:not(.feed-loaded) {
      left: auto !important;
      top: auto !important;
      top: 0px !important;
    }

    &.feed-loaded {
      position: absolute !important;
      left: initial;
      -webkit-animation-name: fadeinSlow;
      -webkit-animation-duration: 1.4s;
      animation-name: fadeinSlow;
      animation-duration: 1.4s;

      &:first-of-type {
        -webkit-animation-name: fadeinSlow;
        -webkit-animation-duration: 0.8s;
        animation-name: fadeinSlow;
        animation-duration: 0.8s;
      }

      &:nth-of-type(2) {
        -webkit-animation-name: fadeinSlow;
        -webkit-animation-duration: 1s;
        animation-name: fadeinSlow;
        animation-duration: 1s;
      }

      &:nth-of-type(3) {
        -webkit-animation-name: fadeinSlow;
        -webkit-animation-duration: 1.2s;
        animation-name: fadeinSlow;
        animation-duration: 1.2s;
      }

      &:nth-of-type(4) {
        -webkit-animation-name: fadeinSlow;
        -webkit-animation-duration: 1.4s;
        animation-name: fadeinSlow;
        animation-duration: 1.4s;
      }

      &:nth-of-type(5) {
        -webkit-animation-name: fadeinSlow;
        -webkit-animation-duration: 1.6s;
        animation-name: fadeinSlow;
        animation-duration: 1.6s;
      }

      &:nth-of-type(6) {
        -webkit-animation-name: fadeinSlow;
        -webkit-animation-duration: 1.8s;
        animation-name: fadeinSlow;
        animation-duration: 1.8s;
      }
    }
  }
}

.feed-dynamic a.all-messages {
  display: none !important;
}

/* Nyheter ny feed september - feed alle i en feed! klassen: one-feed */

.not-for-ansatte #main .feed-dynamic.one-feed ul.items {
  li:after {
    content: "";
    display: block;
    width: 100%;
    height: 30px;
  }
}

/* Load more */
.feed-dynamic-load-more {
  margin-top: 40px;
}

/* Notert */
#main .grid-container .notert-list-item .vrtx-frontpage-box.notert {
  width: 100%;
  height: auto;
  max-height: 435px;
  overflow: hidden;
}
#main .grid-container .vrtx-frontpage-box.notert {
  width: 100%;
  height: auto !important;
  min-height: 380px;
  max-height: 435px;
  overflow: hidden;
  background-color: @color-neutral--light;
  padding: 20px;
  h2 {
    margin-top: 20px;
    margin-left: -20px;
    margin-bottom: 20px;
    background: @color-secondary;
    display: inline-block;
    padding: 0px 14px;
    font-weight: normal;
    a {
      color: white;
      font-family: georgia;
      font-size: 34px !important;
      font-size: 3.4rem !important;
    }
  }
  .vrtx-box-content a {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 24px;
    line-height: 2.4rem;
    font-family: arial;
    display: block;
    font-weight: bold;
    padding-left: 15px;
    color: @color-dark;
    &:hover,
    &:focus {
      color: @color-secondary !important;
    }
    &::before {
      content: "";
      width: 6px;
      height: 6px;
      background: @color-dark;
      display: inline-block;
      margin-right: 10px;
      margin-top: 8px;
      margin-left: -15px;
    }
  }
  .vrtx-feed {
    margin: 0px 15px;
    .items li {
      list-style-type: square;
    }
  }
}

/*****NEWSLETTER*****/
.not-for-ansatte #main .newsletter-icon {
  min-height: 150px;
  height: auto;
  .vrtx-frontpage-box-picture {
    width: 100%;
    height: 185px;
    display: block;
    text-align: center;
    padding-top: 0px;
    img {
      height: 100%;
      width: auto;
      margin-top: -6px;
    }
  }
}


/* Row all colored */
#vrtx-frontpage #main .grid-container.row-last-colored + .row-first-colored {
  border-top: none;
}
#main #vrtx-main-content .grid-color-grey.row-all-colored {
  background: @color-primary;
  &:last-of-type {
    background: @alternateRowColor;
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  #vrtx-frontpage
    #main
    .grid-container:not(.row-last-colored)
    + .grid-container:not(.row-first-colored) {
    padding-top: 0px;
  }
  #vrtx-frontpage
    #main
    .grid-container:not(.row-last-colored):not(.row-thirds-double):not(.row-all-no-margin-bottom):not(.row-all-hidden-boxes):not(.row-all-hidden-boxes-responsive)
    + .grid-container:not(.row-first-colored) {
    margin-top: -25px;
  }
  #vrtx-frontpage #main .grid-container {
    margin-bottom: 0px;
  }
  /* remove borders on grid container */
  #vrtx-frontpage #main .grid-container.row-last-colored + .row-first-colored,
  #vrtx-frontpage
    #main
    .grid-container:not(.row-last-colored)
    + .grid-container:not(.row-first-colored) {
    border-top: none;
  }
  #vrtx-frontpage
    #main
    .vrtx-frontpage-box.grey-box-light:not(.responsive-hide)
    + .grey-box-light,
  #vrtx-frontpage
    #main
    .vrtx-frontpage-box.grey-box:not(.responsive-hide)
    + .grey-box,
  #vrtx-frontpage
    #main
    .vrtx-frontpage-box.grey-clip-box:not(.responsive-hide)
    + .grey-clip-box,
  #vrtx-frontpage #main .vrtx-frontpage-box.red:not(.responsive-hide) + .red,
  #vrtx-frontpage
    #main
    .vrtx-frontpage-box:not(.grey-box):not(.grey-clip-box):not(.grey-box-light):not(.red):not(.responsive-hide)
    + .vrtx-frontpage-box:not(.grey-box):not(.grey-clip-box):not(.grey-box-light):not(.red) {
    border-top: none;
  }
  /*newsfeed*/
  /* feed: page top */
  #vrtx-frontpage #main .vrtx-frontpage-box.big-image .item-title {
    padding-bottom: 15px;
  }
  #vrtx-frontpage #main #vrtx-main-content-1.big-image {
    margin-top: -15px;
    h3 {
      padding: 0px 15px 15px 15px;
    }
    .content-over-image h3 {
      padding: 0;
    }
  }

  .vrtx-frontpage-full-width
    #main
    #vrtx-main-content
    .grid-container
    .row-full-width {
    background: none !important;
    margin-top: 0px;
    .full-width-picture {
      margin-top: -15px;
      .vrtx-image {
        opacity: 1;
      }
    }
    #vrtx-main-content-2.full-width-picture .wrapper {
      width: 100%;
      position: relative;
      left: 0%;
      margin-left: 0px;
      bottom: 0px;
      .form-element-wrapper {
        background: transparent;
        padding: 0px;
        a.item-title {
          color: @color-primary;
          text-shadow: none;
        }
      }
    }
  }

  #vrtx-frontpage #main .grid-container.full-width-picture-bg {
    margin-top: -15px;
  }
  #vrtx-frontpage
    #main
    #vrtx-main-content
    .grid-container
    .row-full-width
    .full-width-picture {
    .vrtx-box-content.content-over-image {
      max-width: 100%;
      margin-top: 0px;
      padding: 15px 15px 25px 15px;
    }
    .form-element-wrapper {
      display: block;
      position: relative;
      max-width: 100%;
      width: 100%;
      bottom: 0;
    }
  }

  #vrtx-frontpage #main #total-main {
    .vrtx-frontpage-box {
      height: auto !important;
      /*margin-bottom: 20px;*/
      &:last-child {
        margin-bottom: 40px;
      }
      ul li {
        a.vrtx-image {
          margin-bottom: 0px;
        }
      }
      .vrtx-frontpage-box-picture {
        margin-bottom: 0px;
      }
      &.big-image {
        border-bottom: 2px solid @color-lines;
        a.item-title {
          font-size: 28px;
          font-size: 2.8rem;
          line-height: 38px;
          line-height: 3.8rem;
        }
      }
      &.feed-image-over.vertical-feed {
        ul li {
          margin-top: 20px;
          padding-bottom: 20px;
          margin-bottom: 20px;
          .vrtx-image,
          a.item-title {
            padding: 0 15px;
            padding-top: 0px;
            padding-right: 15px;
            padding-bottom: 0px;
            padding-left: 15px;
          }
        }
      }
      &.vertical-feed {
        padding-left: 0px;
        padding-right: 0px;
      }
      &.feed-dynamic,
      &.vertical-feed {
        margin-bottom: 0px;
        margin-top: 0px;
        .vrtx-box-content {
          padding-top: 0px;
          .vrtx-feed ul.items {
            li:not(.notert-list-item):not(.debatt1):not(.debatt2):not(.debatt3) {
              height: auto;
              max-height: none;
              min-height: auto;
              display: flex;
              border-bottom: 2px solid @color-lines !important;
              .item-title {
                font-size: 17px;
                font-size: 1.7rem;
                line-height: 2.7rem;
                max-height: none;
              }
              &.item-1:first-of-type {
                margin-top: -20px !important;
              }
              &.item-2 {
                margin-bottom: 20px;
              }
              a.vrtx-image {
                max-height: none;
                overflow: hidden;
                height: auto;
                vertical-align: middle;
                &:first-child img {
                  width: 100%;
                  max-height: none;
                  display: inline-flex;
                  max-width: none;
                }
              }
            }
            li {
              max-height: none;
              &.notert-list-item {
                margin: 0px 0px 5px 0px;
                .notert {
                  max-height: none;
                  border-bottom: 2px solid @color-lines;
                  margin-bottom: 0px;
                  h2 {
                    margin-top: 0px;
                  }
                  a.item-title {
                    padding: 10px 15px 10px 30px;
                    font-size: 17px;
                    font-size: 1.7rem;
                    line-height: 2.7rem;
                  }
                }
              }
              &::after {
                display: none;
              }
              &.debatt1 .debatt,
              &.debatt3 .debatt {
                margin-bottom: 20px;
              }
              &.debatt2 .debatt {
                margin-top: -20px;
              }
            }
          }
        }
      }

      &.one-feed {
        .vrtx-box-content .vrtx-feed ul.items {
          margin-top: 40px;
        }
        .vrtx-box-content .vrtx-feed ul.loaded {
          margin-top: 20px;
        }
      }

      &.debatt {
        height: auto;
        max-height: none;
        min-height: auto;
        height: auto;
        margin-left: 0px;
        border-bottom: 2px solid @color-lines;
        h2 {
          position: relative;
          display: inline-block;
          width: 100%;
          text-align: center;
          margin: 0px;
          top: 0px;
          padding-top: 0px;
          a {
            display: block;
            padding: 10px 20px;
            font-weight: normal;
            background: @color-primary;
          }
        }
        .vrtx-frontpage-box-picture {
          max-height: none;
          overflow: hidden;
          height: auto;
          width: 100%;
          display: block;
          padding-top: 0px;
          a {
            height: auto;
            max-height: none;
            max-height: 300px;
          }
        }
        .vrtx-box-content {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }

      &.notert  {
        margin-top: 0px;
        padding: 0px 15px;
        height: auto;
        min-height: auto;
        max-height: fit-content;
        margin-bottom: 0px;
        li {
          margin-bottom: 20px;
        }
        a.item-title {
          font-size: 1.8rem !important;
          font-size: 18px !important;
          line-height: 24px;
          line-height: 2.4rem;
          &::before {
            content: none;
            display: none;
          }
        }
        h2 {
          padding-top: 0px !important;
        }
      }
    }
    .feed-dynamic-load-more {
      margin-top: 0px;
      padding: 0px 20px 50px 20px;
      button {
        margin-top: 0px;
      }
    }
  }
  .three-column-feed.masonry .vrtx-feed ul li {
    border-bottom: 0 !important;
    padding: 0 0 20px !important;
  }
  .three-column-feed.masonry .vrtx-feed ul li .item-title,
  .three-column-feed.masonry .vrtx-feed ul li .published-date,
  .three-column-feed.masonry .vrtx-feed ul li .vrtx-image {
    padding: 0 15px;
  }
  .three-column-feed.masonry .vrtx-feed ul li .item-description {
    display: none;
  }

  /* newsletter */
  .not-for-ansatte #main #vrtx-content {
    .newsletter-icon {
      display: none;
    }
    .vrtx-frontpage-box.nyhetsbrev-forside {
      padding-top: 30px;
      margin-bottom: 45px;
      &.row-color-grey {
        margin-top: 30px;
      }
      .vrtx-box-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        h2 {
          font-size: 24px;
          font-size: 2.4rem;
          margin: 0;
          text-align: center;
        }
        form {
          width: 80%;
          display: flex;
          flex-direction: column;
          row-gap: 35px;
          margin: 20px auto;
        }
        .button-wrapper {
          width: 100%;
          height: 45px;
          margin: 0px auto;
        }
      }
    }
  }

}
/*------------------------------------*\
# colour
\*------------------------------------*/

// Primary, secondary etc...
@color-primary: #2d2d2e; //mork gra
@color-secondary: #e32526; //rod

// Neutral colors
@color-lines: #eaeaea;
@color-neutral--light: #f4f7f8; //lys gra (notert)

// Suplementary colors
@color-light: #ffffff;
@color-dark: #2b2b2b;

@alternateRowColor: #f5f7f8;
